<template>
  <div class="">
    <navVue class=""></navVue>

    <div dir="ltr" class="CreateQuiz-Page">
      <div>
        <div class="row my-5">
          <div class="col-12">
            <router-link to="/manage/emails/templates" class="goBackLink"
              ><BIconArrowLeft class="mr-2" />Go Back</router-link
            >
          </div>
        </div>

        <div class="row">
          <!-- Left Section Start -->
          <div class="col-12 col-md-3 pr-0 left-section">
            <div class="">
              <div class="title">
                <p>Email Templates</p>
              </div>
              <div class="subTitle">
                <p>
                  Our professional templates are designed to help you create
                  engaging email templates quickly and easily.
                </p>
              </div>
            </div>
          </div>
          <!-- Left Section end -->
          <div class="col-12 col-md-9 h-100 px-4">
            <div class="">
              <div class="" v-if="isChooseTempLoading">
                <div class="row">
                  <div
                    class="col-6 col-md-6 col-lg-6 mb-4"
                    :class="isShopifyLayout ? 'col-xl-6' : 'col-xl-4'"
                    v-for="index in 6"
                    :key="index"
                  >
                    <EmailTemplateSkeleton />
                  </div>
                </div>
              </div>

              <div v-else>
                <div
                  class="QuizTempContainer"
                  id="create-quiz-scrollSpy-container"
                  v-if="Templates.length"
                >
                  <div class="row">
                    <div
                      class="col-6 col-md-6 col-lg-6 mt-2 mb-3"
                      v-for="(template, index) in Templates"
                      :key="index"
                      :class="isShopifyLayout ? 'col-xl-6' : 'col-xl-4'"
                    >
                      <div
                        style="
                          box-shadow: 0px 4px 10px rgba(10, 4, 17, 0.15);
                          border-bottom-left-radius: 12px;
                          border-bottom-right-radius: 12px;
                        "
                      >
                        <LazyImage
                          v-if="template.image"
                          :src="ImageSrc(template.image)"
                          :key="template.image"
                          alt=""
                          style="
                            width: 100%;
                            height: auto;
                            max-height: 350px;
                            object-fit: contain;
                          "
                        />

                        <div class="row m-0">
                          <div class="col-12">
                            <div class="py-2" style="">
                              <div class="">
                                <p
                                  class="font-weight-bold"
                                  style="font-size: 16px; margin-bottom: 5px"
                                >
                                  {{ template.name }}
                                </p>
                              </div>
                              <div>
                                <p
                                  class=""
                                  style="
                                    font-size: 12px;
                                    margin-bottom: 10px;
                                    overflow: auto;
                                  "
                                  v-if="template.description"
                                >
                                  {{ template.description }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class=" ">
                          <div
                            class="d-flex flex-row flex-md-column flex-lg-row px-3 pb-2"
                          >
                            <button
                              @click="SelectTemplate(template)"
                              class="outline-none text-white outline-none border-0 mx-1 w-100 px-4 py-2 choose-btn"
                              style=""
                            >
                              Choose
                            </button>
                            <!-- <AddQuiz :selectedQuiz="quiz"/> -->
                            <button
                              @click.stop="OpenPreview(template)"
                              class="outline-none text-white outline-none border-0 w-100 mx-1 px-3 py-2 see-demo-btn mt-0 mt-md-2 mt-lg-0"
                              style=""
                            >
                              Preview
                              <b-icon-eye class="ml-2"></b-icon-eye>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <AddQuiz ref="AddQuizRef"/>
        <UpgradePlanModal ref="UpgradeUserPlanModal" :title="upgradeModalTitle" :subText="upgradeModalsubText" @openPlansModalChild="openPlansModal"/> -->

      <!-- Add new Template Start -->
      <modal
        ref="addNewEmailTemplateRef"
        id="addNewEmailTemplateRef"
        size="md"
        title="<span class='px-4'>Create New E-mail Template</span>"
      >
        <div class="p-4">
          <div class="w-100 mb-4 d-flex flex-column">
            <div
              class="CreateEmailInput d-flex align-items-center w-100 pr-3 py-2"
            >
              <input
                type="text"
                class="mx-2 flex-grow-1"
                v-model="templateName"
                placeholder="Enter email template name"
              />
            </div>
          </div>
          <div class="text-center">
            <Button
              :is-loading="isCreateTemplateLoading"
              @click="createNewEmailTempalte"
              >Create
            </Button>
          </div>
        </div>
      </modal>
      <!-- Add new Template End -->

      <!-- Preview Modal Start -->
      <modal
        ref="previewEmailTemplateRef"
        id="previewEmailTemplateRef"
        size="xl"
        title="<span class='px-4'>Preview E-mail Template</span>"
      >
        <div class="p-4">
          <div class="w-100" v-if="previewEmailTemplate">
            <EmailTemplatePreview :previewEmailTemplate="previewEmailTemplate" />
          </div>
        </div>
      </modal>
      <!-- Preview Modal End -->
    </div>
  </div>
</template>

<script>
import navVue from "../../../components/nav.vue";
import Modal from "../../../Layout/Modal.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import EmailTemplateSkeleton from "../Components/EmailTemplateSkeleton.vue";
import Button from "../../../Layout/Button.vue";
import EmailTemplatePreview from "../Components/EmailTemplatePreview.vue";
export default {
  components: {
    EmailTemplateSkeleton,
    navVue,
    Modal,
    Button,
    EmailTemplatePreview,
  },

  data() {
    return {
      isChooseTempLoading: false,
      Templates: [],
      Limitations: {},
      activeCategoryId: "",
      templateName: "Untitled",
      selectedTemplateId: null,
      previewEmailTemplate: null,
      selectedCategory: "profile",
      upgradeModalTitle: "Upgrade Your Plan for More Quizzes",
      upgradeModalsubText:
        "You've hit your quiz limit – great work! To keep the momentum and grow further, upgrade your plan now.",
      isCreateTemplateLoading: false,
    };
  },
  methods: {
    ...mapActions(["fetchData"]),
    openPlansModal() {
      let webUsers = ['web','bc','wordpress']
           if( webUsers.includes(this.getUserType)){
        this.$router.push("/manage/account/pricing");
      } 
      if (this.getUserType == "wix") {
        let url = `https://www.wix.com/apps/upgrade/f67f3bd5-3d73-486c-9bb2-618adcc19fb9`;
        window.open(url, "_blank");
      }
      if (this.getUserType == "shopify") {
        const envPath=process.env.VUE_APP_SHOPIFY_UPGRADE_PLAN_PATH;
      const  url= `https://${this.UserAccountData.name}${envPath}manage/subscription/plans`
      window.open(url,"_parent")
      }
    },

    SelectTemplate(templateId) {
      this.selectedTemplateId = null;
      if (templateId) {
        this.selectedTemplateId = templateId.id;
        this.$refs.addNewEmailTemplateRef.open();
      }
    },
    async getChosenTemplateImage(id) {
      let data = {
        template_id: id,
        image: 1,
        html: 0,
        json: 0,
        is_unlayer_template: 1,
      };

      const response = await this.fetchData({
        axiosMethod: "post",
        resource: "email_templates",
        method: "getDetail",
        params: data,
      });

      if (response.status == "success") {
        return response?.data?.image;
      } else {
        return null;
      }
    },

    async createNewEmailTempalte() {
      if (this.templateName == "" || !this.selectedTemplateId) {
        this.$toasted.show("Please fill required details", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });

        return true;
      }

      this.isCreateTemplateLoading = true;
      try {
        let templateImage = await this.getChosenTemplateImage(
          this.selectedTemplateId
        );

        if (!templateImage) {
          this.$toasted.show("Error Occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.isCreateTemplateLoading = false;
          return true;
        }

        let data = {
          name: this.templateName,
          template_id: this.selectedTemplateId,
          design_image: templateImage,
        };

        const response = await this.fetchData({
          axiosMethod: "post",
          resource: "email_templates",
          method: "save",
          params: data,
        });

        if (response.status == "success") {
          this.$refs.addNewEmailTemplateRef.close();

          let baseUrl = window.location.origin;

      let finalURL =
        baseUrl +
        `/EmailEditor/${response.data.key}`;

      window.open(finalURL, "_self");
          // this.$router.push("/manage/emails/templates");
        }

        // const response = await axios.post(`/sdfsdf`, data);
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isCreateTemplateLoading = false;
      }
    },

    OpenPreview(template) {
      this.previewEmailTemplate = null;
      if (template) {

        let obj = {
          id:template.id,
          mode:'unlayer'
        }

        this.previewEmailTemplate = obj;
        this.$refs.previewEmailTemplateRef.open();
      } else {
        this.$toasted.show("Error occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    ImageSrc(img) {
      return img.startsWith("upload") ? `/${img}` : img;
    },
    async getEmailTemplates() {
      try {
        this.isChooseTempLoading = true;
        let data = {
          per_page: 20,
          page: 1,
        };
        const response = await axios.get("/api/v1/email/templates/list", {
          params: data,
        });

        if (response.status == 200) {
          this.Templates = response?.data?.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error Occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.isChooseTempLoading = false;
      }
    },
  },

  computed: {
    ...mapGetters(["GetTokenFromMetaTag", "getNavbarData"]),
    getUserType() {
      return this.getNavbarData.userType;
    },
    UserAccountData() {
      return this.getNavbarData;
    },
    GetWidth() {
      return window.innerWidth;
    },
    isShopifyLayout() {
      if (this.getUserType == "shopify" || this.getUserType == "wix")
        return true;
      return false;
    },
  },
  mounted() {
    this.getEmailTemplates();
  },
 
};
</script>

<style scoped>
.goBackLink {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #7c8290;
}
.CreateQuiz-Page {
  background: #f6f6f7;
  text-align: left;
  padding: 20px 2%;
  min-height: 95vh;
}

.left-section .title {
  color: #292929;
  font-weight: 600;
  font-size: 22px;
}
.left-section .subTitle {
  color: #6d6b6d;
  font-weight: 400;
  font-size: 16px;
}



.QuizTempContainer {
  position: relative;
  max-height: 80vh;
  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-padding-bottom: 80px;
}

.scrollSpyContainer .list-group-item {
  background-color: transparent;
  border: none;
}
.scrollSpyContainer .list-group-item:hover {
  background: rgba(227, 227, 227, 0.4);
}
.scrollSpyContainer .list-group-item.active {
  background: rgba(227, 227, 227, 0.6);
}
.scrollSpyContainer .list-group-item.active p {
  color: #4d4950;
  font-weight: 700;
}

.QuizTempContainer .templateTop {
  /* line-height: 72px; */
  /* background:red; */
  padding-bottom: 10px;
  margin-bottom: 12px;
  letter-spacing: 0.1em;
  border-bottom: 1px solid rgb(227, 227, 227);
}
.QuizTempContainer .templateTitle {
  color: rgb(38, 38, 39);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  /* line-height: 72px; */
}

.Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 72px;
  color: #4d4950;
}
.btn-tab {
  background: transparent;
  border: 1px solid #cbcddc;
  color: #4d4950;
  font-weight: 600;
  font-size: 14px;
  margin: 0px 4.5px;
}
.btn-tab.active {
  background: #4d1b7e;
  border: 1px solid #4d1b7e;
  color: #ffffff;
}
.w-90 {
  width: 90%;
}
.clear-page-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-border-bottom {
  border-bottom: 1px solid #cbcddc;
  height: 214px;
}
.see-demo-btn {
  background: transparent;
  border: 1px solid #b3afb6 !important;
  color: #6d6b6d !important;
  border-radius: 6px;
}
.see-demo-btn:hover {
  border: 1px solid #b3afb6 !important;
  background: #b3afb6 !important;
  color: #ffffff !important;
}
.choose-btn {
  background: #4d4950;
  border: 1px solid #4d4950;
  border-radius: 6px;
}
.choose-btn:hover {
  border: 1px solid #4d4950;
  background: #4d4950cc;
  color: #ffffff !important;
}

.chooseTemplateInput {
  border: 0.8px solid #b3afb6;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
}
/* .chooseTemplateInput input{
    background:transparent;
    border:none;
    outline:none;
  } */
.QuizNameInput:focus {
  border: none !important;
  outline: none !important;
}
.selected-template {
  border: 4px solid #4d1b7e;
  border-radius: 12px;
}
.CreateBtn:disabled {
  opacity: 60%;
}

.CreateEmailInput {
  border: 0.8px solid #b3afb6;
  box-sizing: border-box;
  border-radius: 6px;
}

.CreateEmailInput input {
  background: transparent;
  border: none;
  outline: none;
}
.CreateEmailInput input:focus {
  border: none;
  outline: none;
}
</style>
